@import '~@proton/styles/scss/lib';

.shared-page-layout {
	&-loader {
		color: var(--primary);
	}

	&-container {
		margin-inline: auto;
		border-radius: var(--border-radius-lg);
		min-block-size: rem(315); // To fit exactly 4 items on different screens before its expanded.
	}

	&-encryption-block-text {
		color: var(--signal-success);
	}

	&-bg,
	&-bg .header {
		background-color: #f6fbff;
	}

	&-bg {
		flex-wrap: wrap;
		gap: var(--space-2);
		padding-block: var(--space-2);
	}
}

.shared-folder-empty-placeholder-container {
	position: relative;
	padding-block-start: rem(38);
}

.shared-folder-empty-placeholder-text {
	max-inline-size: rem(380);
}

.collapsing-breadcrumbs.shared-folder-header-breadcrumbs {
	margin-inline-start: -0.25em;
}
