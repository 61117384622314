@import '~@proton/styles/scss/lib';

.shared-url-file-browser {
	overflow: hidden;

	@include media('<=small') {
		margin-inline: -2.5vw;
	}
}

.flex .shared-url-file-browser {
	flex: 1;
}

@media (hover: hover) and (pointer: fine) {
	.mouse\:group-hover\:opacity-100.file-browser-list--download-button[disabled] {
		opacity: 0;
	}
}
