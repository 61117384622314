.user-info {
	.user-initials {
		font-size: 12px;
		line-height: 17.5px;
	}

	.rounded {
		border-radius: var(--border-radius-md);
	}
}

.user-info:hover {
	.user-initials {
		background-color: var(--interaction-default-hover);
	}
}

@media (width <= 42.5em) {
	.user-info {
		padding: 0;
		inline-size: unset;
		border: 0;

		& > svg {
			display: none;
		}
	}
}
