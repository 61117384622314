@import '~@proton/styles/scss/lib';

.shared-page-content-header-buttons {
	inline-size: 100%;
	margin: auto;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(min(100%, 10rem), 1fr));
	grid-gap: 0.5rem;
	justify-content: center;
	max-inline-size: 23rem;

	@include media('>small') {
		inline-size: auto;
		margin: 0;
		display: flex;
		align-items: center;
		max-inline-size: 100%;
	}
}
