@import '~@proton/styles/scss/lib';

.upsell-floating-modal {
	position: fixed;
	inset-block: auto 0;
	inset-inline: auto 0;
}

.upsell-floating-modal-container {
	background-color: #f4f1ff;
}

.upsell-floating-modal-dialog {
	--margin: #{rem(24)};
}

.upsell-floating-modal-tooltip {
	// Override default theme
	--text-norm: black;
}
